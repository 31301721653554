import {
  GET_LITESUBGROUP,
  LITESUBGROUP_ERROR,
  SHOW_LITESUBGROUP_LOADING,
  CREATE_LITEMEASUREMENT_SUCCESS,
  DELETE_LITEMEASUREMENT,
  EDIT_LITEMEASUREMENT_LOGBOOK_SUCCESS,
} from '../actions/types';

const initialState = {
  litesubgrouplist: [],
  litesubgroupID: null,
  litesubgroupdetails: null,
  litesubgroupLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITESUBGROUP_LOADING:
      return {
        ...state,
        litesubgroupLoading: true,
      };
    case GET_LITESUBGROUP:
      return {
        ...state,
        litesubgrouplist: payload,
        litesubgroupLoading: false,
      };
    case CREATE_LITEMEASUREMENT_SUCCESS:
      return {
        ...state,
        litesubgrouplist: [payload, ...state.litesubgrouplist],
        litesubgroupLoading: false,
      };
    case DELETE_LITEMEASUREMENT:
      return {
        ...state,
        litesubgrouplist: state.litesubgrouplist.filter(
          (measure) => measure._id !== payload
        ),
        litesubgroupLoading: false,
      };
    case EDIT_LITEMEASUREMENT_LOGBOOK_SUCCESS:
      return {
        ...state,
        litesubgrouplist: state.litesubgrouplist.map((subgroup) =>
          subgroup._id === payload._id
            ? {
                ...subgroup,
                atSea: payload.atSea,
                atPort: payload.atPort,
              }
            : subgroup
        ),
        litesubgroupLoading: false,
      };
    case LITESUBGROUP_ERROR:
      return {
        ...state,
        error: payload,
        litesubgroupLoading: false,
      };
    default:
      return state;
  }
}
