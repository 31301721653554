import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import PrivateRoute from 'components/routing/PrivateRoute';

/**
 * olain-tarcina-sc-681
 */

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/jobdetails/:id`}
          component={lazy(() => import(`./jobdetails/JobDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/logbook`}
          component={lazy(() => import(`./logbook/Logbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/logbookdetails/:id`}
          component={lazy(() => import(`./logbookdetails/LogbookDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/equipment`}
          component={lazy(() => import(`./equipment/Equipment`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/manageequipment`}
          component={lazy(() => import(`./manageequipment/ManageEquipment`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/inventory`}
          component={lazy(() => import(`./inventory/Inventory`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/reports`}
          component={lazy(() => import(`./reports/Reports`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/watchkeeping`}
          component={lazy(() => import(`./watchkeeping/Watchkeeping`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/employees`}
          component={lazy(() => import(`./employees/Employees`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/profiledetails/:id`}
          component={lazy(() => import(`./profiledetails/ProfileDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/guests`}
          component={lazy(() => import(`./guests/Guests`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/guestdetails/:id`}
          component={lazy(() => import(`./guestdetails/Guestdetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/vesseldocs`}
          component={lazy(() => import(`./vesseldocs/Vesseldocs`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/ism`}
          component={lazy(() => import(`./ism/Ism`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/supplier`}
          component={lazy(() => import(`./supplier/Supplier`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/spareparts`}
          component={lazy(() => import(`./spareparts/Spareparts`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/superadmin`}
          component={lazy(() => import(`./superadmin/Superadmin`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/help`}
          component={lazy(() => import(`./help/Help`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/editprofile/:id`}
          component={lazy(() => import(`./editprofile/EditProfile`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/hoursofrest`}
          component={lazy(() => import(`./hoursofrest/HoursOfRest`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/onboard`}
          component={lazy(() => import(`./onboard/Onboard`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/myvessel`}
          component={lazy(() => import(`./myvessel/MyVessel`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/vacationleaves`}
          component={lazy(() => import(`./vacationleaves/VacationLeaves`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/portlogbook`}
          component={lazy(() => import(`./portlogbook/PortLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sealogbook`}
          component={lazy(() => import(`./sealogbook/SeaLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/portbridgelogbook`}
          component={lazy(() =>
            import(`./portbridgelogbook/PortBridgeLogbook`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/seabridgelogbook`}
          component={lazy(() => import(`./seabridgelogbook/SeaBridgeLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/crewlist`}
          component={lazy(() => import(`./crewlist/CrewList`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/superadmin/:id`}
          component={lazy(() => import(`./boatdetails/BoatDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminequipment`}
          component={lazy(() => import(`./adminequipment/AdminEquipment`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminjobs`}
          component={lazy(() => import(`./adminjobs/AdminJobs`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminjobs/:id`}
          component={lazy(() => import(`./adminjobdetails/Adminjobdetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminusers`}
          component={lazy(() => import(`./adminusers/AdminUsers`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminforms`}
          component={lazy(() => import(`./adminforms/AdminForms`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminforms/:id`}
          component={lazy(() => import(`./formdetails/FormDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/watchkeeping/:id`}
          component={lazy(() => import(`./watchdetails/WatchDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/calendar/:id`}
          component={lazy(() => import(`./watchcalendar/WatchCalendar`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/calendar`}
          component={lazy(() => import(`./newcalendar/NewCalendar`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/watchkeepinglogs`}
          component={lazy(() => import(`./watchkeepinglogs/WatchkeepingLogs`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/manageleaves`}
          component={lazy(() => import(`./manageleaves/ManageLeaves`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/ismform/:id`}
          component={lazy(() => import(`./ismform/IsmForm`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/formarchives`}
          component={lazy(() => import(`./formarchives/FormArchives`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/ismdraft/:id`}
          component={lazy(() => import(`./ismdraft/IsmDraft`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/formarchives/:id`}
          component={lazy(() => import(`./ismformdetails/IsmFormDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/shipyard`}
          component={lazy(() => import(`./shipyard/Shipyard`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/teams`}
          component={lazy(() => import(`./teams/Teams`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/records`}
          component={lazy(() => import(`./records/Records`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/decks`}
          component={lazy(() => import(`./decks/Decks`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users/Users`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/userdetails/:id`}
          component={lazy(() => import(`./userdetails/UserDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/projectdetails/:id`}
          component={lazy(() => import(`./projectdetails/ProjectDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/shipyarprofile/:id`}
          component={lazy(() => import(`./shipyardprofile/ShipyardProfile`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/boatadminforms`}
          component={lazy(() => import(`./boatadminforms/BoatAdminForms`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/boatadminforms/:id`}
          component={lazy(() => import(`./boatformdetails/BoatFormDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sections`}
          component={lazy(() => import(`./sections/Sections`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/orders`}
          component={lazy(() => import(`./orders/Orders`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/archivehistory/:id`}
          component={lazy(() => import(`./archivehistory/ArchiveHistory`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/log`}
          component={lazy(() => import(`./log/Log`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/officiallogbook/:id`}
          component={lazy(() => import(`./officiallogbook/OfficialLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/chiefofficer/:id`}
          component={lazy(() => import(`./chiefofficer/ChiefOfficer`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/garbagerecord/:id`}
          component={lazy(() => import(`./garbagerecord/GarbageRecord`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/gmdss/:id`}
          component={lazy(() => import(`./gmdss/Gmdss`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/oilrecord/:id`}
          component={lazy(() => import(`./oilrecord/OilRecord`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/cayman/:id`}
          component={lazy(() => import(`./cayman/Cayman`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/createlogbook/:id`}
          component={lazy(() => import(`./createlogbook/CreateLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/officiallogbook/logdetails/:id`}
          component={lazy(() =>
            import(`./officiallogbooklogdetails/OfficialLogbookLogDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/chiefofficer/logdetails/:id`}
          component={lazy(() =>
            import(`./chiefofficerlogdetails/ChiefOfficerLogDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/garbagerecord/logdetails/:id`}
          component={lazy(() =>
            import(`./garbagerecordlogdetails/GarbageRecordLogDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/gmdss/logdetails/:id`}
          component={lazy(() => import(`./gmdsslogdetails/GmdssLogDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/oilrecord/logdetails/:id`}
          component={lazy(() =>
            import(`./oilrecordlogdetails/OilRecordLogDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/cayman/logdetails/:id`}
          component={lazy(() => import(`./caymanlogdetails/CaymanLogDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/custom/logdetails/:id`}
          component={lazy(() => import(`./customlogdetails/CustomLogDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/custom/logbook/:id`}
          component={lazy(() => import(`./customlogbook/CustomLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting`}
          component={lazy(() => import(`./accounting/Accounting`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/accountingreport`}
          component={lazy(() => import(`./accountingreport/AccountingReport`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/hoursofrest/hoursofrestprint`}
          component={lazy(() => import(`./hoursofrestprint/HoursOfRestPrint`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/terms`}
          component={lazy(() => import(`./terms/Terms`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/boatspareparts`}
          component={lazy(() => import(`./boatspareparts/BoatSpareParts`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/crewemail`}
          component={lazy(() => import(`./crewemail/CrewEmail`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sales`}
          component={lazy(() => import(`./sales/Sales`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sales/:id`}
          component={lazy(() => import(`./salesdetails/SalesDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/management`}
          component={lazy(() => import(`./management/Management`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/management/:id`}
          component={lazy(() =>
            import(`./managementdetails/ManagementDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/managementhome`}
          component={lazy(() => import(`./managementhome/ManagementHome`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/equipmentphotos`}
          component={lazy(() => import(`./equipmentphotos/EquipmentPhotos`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/change-password`}
          component={lazy(() => import(`./changepassword/ChangePassword`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sales/clients/:id`}
          component={lazy(() => import(`./clientdetails/ClientDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/accountinginvoice/:id`}
          component={lazy(() =>
            import(`./accountinginvoice/AccountingInvoice`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/invoicedetails/:id`}
          component={lazy(() => import(`./invoicedetails/InvoiceDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/invoiceedit/:id`}
          component={lazy(() => import(`./invoiceedit/InvoiceEdit`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/chat`}
          component={lazy(() => import(`./chat/Chat`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/equipment/:id`}
          component={lazy(() => import(`./jobequipment/JobEquipment`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/shipyard/shipyarddetails/:id`}
          component={lazy(() => import(`./shipyarddetails/ShipyardDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/reports/reportsemail`}
          component={lazy(() => import(`./reportsemail/ReportsEmail`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/salescalendar`}
          component={lazy(() => import(`./salescalendar/SalesCalendar`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/litesuperadmin`}
          component={lazy(() => import(`./liteadmin/LiteAdmin`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/liteadminusers`}
          component={lazy(() => import(`./liteadminusers/LiteAdminUsers`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/liteboatdetails/:id`}
          component={lazy(() => import(`./liteboatdetails/LiteBoatDetails`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
