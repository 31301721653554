import {
  GET_JOBDETAILS_LITEHISTORY,
  LITEHISTORY_ERROR,
  SHOW_LITEHISTORY_LOADING,
  GET_GROUP_LITEHISTORY,
  SHOW_GROUP_LITEHISTORY_LOADING,
  SUBMIT_LITEJOB_SUCCESS,
  EDIT_JOB_LITEHISTORY,
  EDIT_SOLO_LITEHISTORY,
  MORE_JOB_DETAILS_LITEHISTORY,
  SEARCH_JOB_DETAILS_LITEHISTORY,
} from '../actions/types';

/**
 *
 */

const initialState = {
  litehistorylist: [],
  morelitehistorylist: [],
  searchlitehistorylist: [],
  grouplitehistorylist: [],
  litehistoryID: null,
  litehistorydetails: null,
  litehistoryLoading: false,
  grouplitehistoryLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEHISTORY_LOADING:
      return {
        ...state,
        litehistoryLoading: true,
      };
    case SHOW_GROUP_LITEHISTORY_LOADING:
      return {
        ...state,
        grouplitehistoryLoading: true,
      };
    case GET_JOBDETAILS_LITEHISTORY:
      return {
        ...state,
        litehistorylist: payload,
        litehistoryLoading: false,
      };
    case GET_GROUP_LITEHISTORY:
      return {
        ...state,
        grouplitehistorylist: payload,
        grouplitehistoryLoading: false,
      };
    case SUBMIT_LITEJOB_SUCCESS:
      return {
        ...state,
        litehistorylist: [payload, ...state.litehistorylist],
        litegrouphistorylist: [payload, ...state.grouplitehistorylist],
        litehistoryLoading: false,
        grouplitehistoryLoading: false,
      };
    case EDIT_JOB_LITEHISTORY:
      return {
        ...state,
        grouplitehistorylist: state.grouplitehistorylist.map((history) =>
          history._id === payload._id
            ? {
                ...history,
                workHistory: payload.workHistory,
                files: payload.files,
                histoPhoto: payload.histoPhoto,
                editHistory: payload.editHistory,
              }
            : history
        ),
        litehistoryLoading: false,
      };

    case EDIT_SOLO_LITEHISTORY:
      return {
        ...state,
        litehistorylist: state.litehistorylist.map((history) =>
          history._id === payload._id
            ? {
                ...history,
                workHistory: payload.workHistory,
                files: payload.files,
                histoPhoto: payload.histoPhoto,
                editHistory: payload.editHistory,
              }
            : history
        ),
        litehistoryLoading: false,
      };
    case MORE_JOB_DETAILS_LITEHISTORY:
      return {
        ...state,
        morelitehistorylist: state.morelitehistorylist.concat(payload),
        historyLoading: false,
      };
    case SEARCH_JOB_DETAILS_LITEHISTORY:
      return {
        ...state,
        searchlitehistorylist: state.searchlitehistorylist.concat(payload),
        litehistoryLoading: false,
      };
    case LITEHISTORY_ERROR:
      return {
        ...state,
        error: payload,
        litehistoryLoading: false,
      };
    default:
      return state;
  }
}
