import {
  SHOW_LITEBOAT_LOADING,
  GET_LITEBOAT_DETAILS,
  LITEBOAT_ERROR,
  GET_LITEBOATS,
  SHOW_EDIT_LITEBOAT_LOADING,
  DELETE_LITEBOATS,
} from '../actions/types';

const initialState = {
  liteboatlist: [],
  liteboatID: null,
  liteboatdetails: null,
  liteboatLoading: false,
  liteeditBoatLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEBOAT_LOADING:
      return {
        ...state,
        liteboatLoading: true,
      };
    case SHOW_EDIT_LITEBOAT_LOADING:
      return {
        ...state,
        liteeditBoatLoading: true,
      };
    case GET_LITEBOATS:
      return {
        ...state,
        liteboatlist: payload,
        liteboatLoading: false,
      };
    case GET_LITEBOAT_DETAILS:
      return {
        ...state,
        liteboatdetails: payload,
        liteboatLoading: false,
        liteeditBoatLoading: false,
      };
    case LITEBOAT_ERROR:
      return {
        ...state,
        error: payload,
        liteboatLoading: false,
        liteeditBoatLoading: false,
      };
    case DELETE_LITEBOATS:
      return {
        ...state,
        liteboatlist: state.liteboatlist.filter((boat) => boat._id !== payload),
        liteboatLoading: false,
        liteeditBoatLoading: false,
      };
    default:
      return state;
  }
}
