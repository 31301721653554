import {
  GET_LITE_WORKLISTS,
  SHOW_LITE_WORKLIST_LOADING,
  LITE_WORKLIST_ERROR,
  SHOW_EDIT_LITE_WORKLIST_LOADING,
  CREATE_LITE_WORKLIST_SUCCESS,
  CREATE_LITE_WORKLIST_FAIL,
  GET_LITE_JOBDETAILS,
  DELETE_LITE_WORKLIST,
  GET_LITEWORKLIST_TASKS,
} from '../actions/types';

/** Modifications
 * olain-tarcina-sc-681 - ymplite registration
 */

const initialState = {
  litejoblist: [],
  liteworklistID: null,
  liteworklistDetails: null,
  liteworklistTasks: null,
  loading: false,
  editloading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITE_WORKLIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SHOW_EDIT_LITE_WORKLIST_LOADING:
      return {
        ...state,
        editloading: true,
      };
    case GET_LITE_WORKLISTS:
      return {
        ...state,
        litejoblist: payload,
        loading: false,
      };
    case LITE_WORKLIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CREATE_LITE_WORKLIST_SUCCESS:
      return {
        ...state,
        litejoblist: [payload, ...state.litejoblist],
        loading: false,
      };
    case DELETE_LITE_WORKLIST:
      return {
        ...state,
        litejoblist: state.litejoblist.filter((job) => job._id !== payload),
      };
    case CREATE_LITE_WORKLIST_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_LITE_JOBDETAILS:
      return {
        ...state,
        liteworklistDetails: payload,
        liteworklistTasks: {
          jobTasks: payload.jobTasks,
        },
        loading: false,
      };
    case GET_LITEWORKLIST_TASKS:
      return {
        ...state,
        liteworklistTasks: {
          jobTasks: payload.jobTasks,
        },
        // modification end - 07.02.2024 - maco-magsael-sc-655
        loading: false,
      };

    default:
      return state;
  }
}
