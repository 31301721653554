import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';

/** Modifications
 * olain-tarcina-sc-681 - ymplite registration
 */

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/register`}
          component={lazy(() => import(`./authentication/register-1`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/reset-password/:token`}
          component={lazy(() => import(`./authentication/reset-password`))}
        />
        {/* Modification start - 09.05.2024 - olain-tarcina-sc-681*/}
        <Route
          path={`${AUTH_PREFIX_PATH}/success`}
          component={lazy(() => import(`./authentication/success`))}
        />
        {/* Modification end - 09.05.2024 - olain-tarcina-sc-681*/}
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${AUTH_PREFIX_PATH}/login`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
