import {
  GET_JOBDETAILS_LITEDOCS,
  GET_LITEFOLDERDETAILS,
  LITEDOCS_ERROR,
  SHOW_LITEDOCS_LOADING,
  GET_PROFILE_LITEDOCS,
  SHOW_LITEFOLDER_LOADING,
  SHOW_LITEFILE_LOADING,
  GET_LITEFOLDERS,
  GET_LITEPAGES,
  GET_LITEFILES,
  SHOW_LITEBOTH_LOADING,
  UPLOAD_LITEMANUAL_SUCCESS,
  UPLOAD_LITESUPPORT_SUCCESS,
  DELETE_LITEDOCS,
  GET_ALL_LITEFILES,
  CREATE_LITEFOLDER_SUCCESS,
  CREATE_LITEFILE_SUCCESS,
  DELETE_LITEFILES,
  SHOW_LITEDONE_LOADING,
  EDIT_LITEFOLDER_SUCCESS,
  SHOW_EDITLITEFOLDER_LOADING,
  DELETE_LITEFOLDER,
  EDIT_LITEFOLDER_PERMISSIONS,
  EDIT_LITEPREVIOUS_SUCCESS,
  EDIT_LITECURRENT_SUCCESS,
  EDIT_LITECERTIFICATE,
} from '../actions/types';

/** Modifications
 *
 */

const initialState = {
  litedoclist: [],
  litedocID: null,
  litedocDetails: null,
  litefolderDetails: null,
  litefolderlist: [],
  litefilelist: [],
  allLiteFiles: [],
  litepagelist: [],
  litedocLoading: false,
  litefolderLoading: false,
  editlitefolderLoading: false,
  litefileLoading: false,
  uploading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEDOCS_LOADING:
      return {
        ...state,
        litedocLoading: true,
      };
    case SHOW_LITEFOLDER_LOADING:
      return {
        ...state,
        litefolderLoading: true,
      };
    case SHOW_EDITLITEFOLDER_LOADING:
      return {
        ...state,
        editlitefolderLoading: true,
      };
    case SHOW_LITEFILE_LOADING:
      return {
        ...state,
        litefileLoading: true,
      };
    case SHOW_LITEBOTH_LOADING:
      return {
        ...state,
        litefolderLoading: true,
        litefileLoading: true,
      };
    case GET_JOBDETAILS_LITEDOCS:
      return {
        ...state,
        litedoclist: payload,
        litedocLoading: false,
      };
    case GET_LITEPAGES:
      return {
        ...state,
        litepagelist: payload,
        litedocLoading: false,
      };
    case GET_PROFILE_LITEDOCS:
      return {
        ...state,
        litedoclist: payload,
        litedocLoading: false,
      };
    case GET_LITEFOLDERS:
      return {
        ...state,
        litefolderlist: payload,
        litefolderDetails: null,
        litefolderLoading: false,
      };
    case GET_LITEFOLDERDETAILS:
      return {
        ...state,
        litefolderDetails: payload,
        litefolderLoading: false,
      };
    case GET_ALL_LITEFILES:
      return {
        ...state,
        allLiteFiles: payload,
        litefilelist: payload.filter(
          (file) => file.mainFile === true || file.mainFolder === true
        ),
        litefolderDetails: null,
        litefileLoading: false,
      };
    case GET_LITEFILES:
      return {
        ...state,
        litefilelist: state.allLiteFiles.filter(
          (file) => file.mainfolderID === payload
        ),
        litefileLoading: false,
      };
    case CREATE_LITEFOLDER_SUCCESS:
      return {
        ...state,
        litefolderlist: [payload, ...state.litefolderlist],
        litefilelist: [payload, ...state.litefilelist],
        allLiteFiles: [payload, ...state.allLiteFiles],
        litefolderLoading: false,
        editlitefolderLoading: false,
      };
    case CREATE_LITEFILE_SUCCESS:
      return {
        ...state,
        allLiteFiles: state.allLiteFiles.concat(payload),
        litefilelist: state.litefilelist.concat(payload),
        litefileLoading: false,
      };
    case UPLOAD_LITEMANUAL_SUCCESS:
      return {
        ...state,
        litedoclist: payload,
        litedocLoading: false,
      };
    case UPLOAD_LITESUPPORT_SUCCESS:
      return {
        ...state,
        litedoclist: state.litedoclist.concat(payload),
        litedocLoading: false,
      };
    case EDIT_LITEFOLDER_SUCCESS:
      return {
        ...state,
        litefilelist: state.litefilelist.map((folder) =>
          folder._id === payload._id
            ? {
                ...folder,
                name: payload.name,
              }
            : folder
        ),
        litefolderlist: state.litefolderlist.map((folder) =>
          folder._id === payload._id
            ? {
                ...folder,
                name: payload.name,
              }
            : folder
        ),
        allLiteFiles: state.allLiteFiles.map((folder) =>
          folder._id === payload._id
            ? {
                ...folder,
                name: payload.name,
              }
            : folder
        ),
        litefolderDetails: payload,
        litefolderLoading: false,
        editlitefolderLoading: false,
      };
    case EDIT_LITEPREVIOUS_SUCCESS:
      return {
        ...state,
        litedoclist: state.litedoclist.map((doc) =>
          doc._id === payload._id
            ? {
                ...doc,
                primary: payload.primary,
              }
            : doc
        ),
      };
    case EDIT_LITECURRENT_SUCCESS:
      return {
        ...state,
        litedoclist: state.litedoclist.map((doc) =>
          doc._id === payload._id
            ? {
                ...doc,
                primary: payload.primary,
              }
            : doc
        ),
        litedocLoading: false,
      };
    case EDIT_LITEFOLDER_PERMISSIONS:
      return {
        ...state,
        litefolderDetails: payload,
      };
    case EDIT_LITECERTIFICATE:
      return {
        ...state,
        litefilelist: state.litefilelist.map((folder) =>
          folder._id === payload._id
            ? {
                ...folder,
                expDate: payload.expDate,
              }
            : folder
        ),
        litefolderLoading: false,
        litefileLoading: false,
      };
    case DELETE_LITEDOCS:
      return {
        ...state,
        litedoclist: state.litedoclist.filter((file) => file._id !== payload),
        litedocLoading: false,
      };
    case DELETE_LITEFILES:
      return {
        ...state,
        allLiteFiles: state.allLiteFiles.filter(
          (file) => file._id !== payload && file.mainfolderID !== payload
        ),
        litefilelist: state.litefilelist.filter(
          (file) => file._id !== payload && file.mainfolderID !== payload
        ),
        litefolderlist: state.litefolderlist.filter(
          (file) => file._id !== payload && file.mainfolderID !== payload
        ),

        litefolderLoading: false,
        litefileLoading: false,
        litefolderDetails: null,
      };
    case DELETE_LITEFOLDER:
      return {
        ...state,
        allLiteFiles: state.allLiteFiles.filter(
          (file) => file.mainfolderID !== payload
        ),
        litefilelist: state.litefilelist.filter(
          (file) => file.mainfolderID !== payload
        ),
        litefolderlist: state.litefolderlist.filter(
          (folder) => folder._id !== payload
        ),
        litefolderLoading: false,
        litefileLoading: false,
        litefolderDetails: null,
      };
    case SHOW_LITEDONE_LOADING:
      return {
        ...state,
        litefileLoading: false,
      };
    case LITEDOCS_ERROR:
      return {
        ...state,
        error: payload,
        litedocLoading: false,
        litefolderLoading: false,
        litefileLoading: false,
      };
    default:
      return state;
  }
}
