import {
  GET_LITEINVENTORY,
  SHOW_LITEINVENTORY_LOADING,
  LITEINVENTORY_ERROR,
  GET_LITEINVENTORY_DETAILS,
  SHOW_EDIT_LITEINVENTORY_LOADING,
  CREATE_LITEINVENTORY,
  EDIT_LITEINVENTORY,
  DELETE_LITEINVENTORY,
  SHOW_DELETE_LITEINVENTORY_LOADING,
  GET_MORE_LITEINVENTORY,
  SEARCH_LITEINVENTORY,
  UPLOAD_LITEINVENTORY_CSV,
} from '../actions/types';

/**
 *
 */

const initialState = {
  liteinventorylist: [],
  moreliteinventorylist: [],
  searchliteinventorylist: [],
  liteinventoryID: null,
  liteinventorydetails: null,
  liteinventoryLoading: false,
  editloading: false,
  deleteLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEINVENTORY_LOADING:
      return {
        ...state,
        liteinventoryLoading: true,
      };
    case SHOW_EDIT_LITEINVENTORY_LOADING:
      return {
        ...state,
        editloading: true,
      };
    case SHOW_DELETE_LITEINVENTORY_LOADING:
      return {
        ...state,
        deleteLoading: true,
      };
    case GET_LITEINVENTORY:
      return {
        ...state,
        liteinventorylist: payload,
        liteinventoryLoading: false,
      };
    case CREATE_LITEINVENTORY:
      return {
        ...state,
        liteinventorylist: [payload, ...state.liteinventorylist],
        moreliteinventorylist: [payload, ...state.liteinventorylist],
        editloading: false,
      };
    case EDIT_LITEINVENTORY:
      return {
        ...state,
        moreliteinventorylist: state.liteinventorylist.map((inventory) =>
          inventory._id === payload._id
            ? {
                ...inventory,
                name: payload.name,
                partNo: payload.partNo,
                totalAmount: payload.totalAmount,
                category: payload.category,
                manufacturer: payload.manufacturer,
                minAmount: payload.minAmount,
                critical: payload.critical,
                private: payload.private,
                locationID: payload.locationID,
                locationBox: payload.locationBox,
                modelNo: payload.modelNo,
                price: payload.price,
                supplier: payload.supplier,
                description: payload.description,
                subCategory: payload.subCategory,
              }
            : inventory
        ),
        liteinventorylist: state.liteinventorylist.map((inventory) =>
          inventory._id === payload._id
            ? {
                ...inventory,
                name: payload.name,
                partNo: payload.partNo,
                totalAmount: payload.totalAmount,
                category: payload.category,
                manufacturer: payload.manufacturer,
                minAmount: payload.minAmount,
                critical: payload.critical,
                private: payload.private,
                locationID: payload.locationID,
                locationBox: payload.locationBox,
                modelNo: payload.modelNo,
                price: payload.price,
                supplier: payload.supplier,
                description: payload.description,
                shelves: payload.shelves,
                expDate: payload.expDate,
                subCategory: payload.subCategory,
              }
            : inventory
        ),
        editloading: false,
      };
    case GET_LITEINVENTORY_DETAILS:
      return {
        ...state,
        liteinventorydetails: payload,
      };
    case DELETE_LITEINVENTORY:
      return {
        ...state,
        liteinventorylist: state.liteinventorylist.filter(
          (inventory) => inventory._id !== payload
        ),
        moreliteinventorylist: state.liteinventorylist.filter(
          (inventory) => inventory._id !== payload
        ),
        deleteLoading: false,
      };
    case GET_MORE_LITEINVENTORY:
      return {
        ...state,
        moreliteinventorylist: state.moreliteinventorylist.concat(payload),
        inventoryLoading: false,
      };
    case SEARCH_LITEINVENTORY:
      return {
        ...state,
        searchliteinventorylist: state.searchliteinventorylist.concat(payload),
        inventoryLoading: false,
      };
    case UPLOAD_LITEINVENTORY_CSV:
      return {
        ...state,
        liteinventorylist: [...payload, ...state.liteinventorylist],
        moreliteinventorylist: [...payload, ...state.moreliteinventorylist],
        inventoryLoading: false,
      };
    case LITEINVENTORY_ERROR:
      return {
        ...state,
        error: payload,
        liteinventoryLoading: false,
      };
    default:
      return state;
  }
}
