import { SIDE_NAV_LIGHT, NAV_TYPE_TOP, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'YMP';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_TOP,
  //topNavColor: '#3e82f7',
  topNavColor: '#2B2E33',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'dark',
  direction: DIR_LTR,
};

/*
{
  "navType": "TOP",
  "sideNavTheme": "SIDE_NAV_LIGHT",
  "navCollapsed": false,
  "topNavColor": "#3e82f7",
  "headerNavColor": "",
  "locale": "en",
  "currentTheme": "dark",
  "direction": "ltr"
}
*/
