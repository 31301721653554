import axios from 'axios';
import {
  SHOW_USER_LOADING,
  GET_USER_DETAILS,
  USER_ERROR,
  UPDATE_PROFILE_SUCCESS,
  SHOW_PROFILE_LOADING,
  PROFILE_ERROR,
  USER_LOADING,
  GET_USERS,
  // Modification start - 04.24.2024 - new-shipyard
  SHOW_USER_SHIPYARD_LOADING,
  // modification start - 05.24.2024 - maco-magsael-sc-643
  GET_JOBDETAILS,
  DELETE_USER,
  // modification end - 05.24.2024 - maco-magsael-sc-643
  // Modification end - 04.24.2024 - new-shipyard
} from './types';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { setNotification } from 'components/shared-components/notification/Notification';
import { loadUser } from './auth';
import emailjs from '@emailjs/browser';

/** Modifications
 *  ymponline-140 - updating profile role will update user role
 *  ympshipyard: create shipyard interface
 *  ympolain-169 - Make email required when creating user
 *  galing: assign random hex color for borderColor and backgroundColor
 *  olain-tarcina-sc-226: user softdelete API
 *  maco-magsael-sc-440: Crew - fix delete user bug
 *  maco-magsael-sc-458: Calendars - develop calendar module
 *  maco-magsael-sc-440: Crew - fix delete user bug
 *  management-module: Build management interface
 *  olain-tarcina-sc-550: Notification to update email
 *  olain-tarcina-sc-553: add email when creating user
 * maco-magsael-sc-568: Management - Overhaul management module
 * new-shipyard
 * maco-magsael-sc-643: Shipyard: New shipyard modifications
 * maco-magsael-sc-661: Shipyard - Add email notifications
 */

// Get user details
export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_USER_LOADING,
    });
    const res = await axios.get(`/api/users/userdetails/${id}`);

    dispatch({
      type: GET_USER_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get all users for boat
export const getWatchkeepers = (boatID) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_USER_LOADING,
    });

    const res = await axios.get(`/api/users/watchkeepers/${boatID}`);

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// modification start - 08.04.2023 - maco-magsael-sc-458
// Get all users for boat
export const getAllUsers = (boatID) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_USER_LOADING,
    });

    const res = await axios.get(`/api/users/boat/${boatID}`);

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// modification end - 08.04.2023 - maco-magsael-sc-458

// Modification start - 04.24.2024 - new-shipyard
// Get all users with email
export const getEmailUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_USER_LOADING,
    });

    const res = await axios.get(`/api/users/all_users`);

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// Modification end - 04.24.2024 - new-shipyard

// Create user
export const createUser = (formData, history) => async (dispatch) => {
  dispatch({
    type: SHOW_PROFILE_LOADING,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const SERVICE_ID = 'service_toqfoo9';
  const TEMPLATE_ID = 'template_5ps2c2e';
  const USER_ID = 'user_WtCjGRDorUo3I1OmblHuB';

  // modification start - 09.09.2022 - galing-159
  let getColor = '#' + Math.random().toString(16).slice(2, 8);
  // modification end - 09.09.2022 - galing-159

  const firstName = formData.firstName;
  const lastName = formData.lastName;
  const username = formData.email;
  const department = formData.department;
  const jobtitle = formData.jobtitle;
  const mailing = formData.mailing;
  const role = formData.role;
  // Modifications - start - 12.09.2022 - ympolain-169
  const email = formData.email;
  // Modifications - end - 12.09.2022 - ympolain-169
  // modification start - 09.02.2022 - galing-159
  const backgroundColor = getColor;
  const borderColor = getColor;
  // modification end - 09.02.2022 - galing-159
  // modification start - 07.22.2023 - management-module
  const managementID = formData.managementID;
  // modification end - 07.22.2023 - management-module
  const password = formData.password;
  // modification start - 01.24.2024 - maco-magsael-sc-568
  const manageBoatID = formData.boatID;
  const manageBoatName = formData.boatName;
  // modification end - 01.24.2024 - maco-magsael-sc-568

  const body = JSON.stringify({
    username,
    department,
    jobtitle,
    mailing,
    role,
    // modification start - 09.02.2022 - galing-159
    backgroundColor,
    borderColor,
    // modification end - 09.02.2022 - galing-159
    // modification start - 07.22.2023 - management-module
    managementID,
    // modification end - 07.22.2023 - management-module
    password,
    // Modification start - 07.12.2023 - olain-tarcina-sc-553
    email,
    // Modification end - 07.12.2023 - olain-tarcina-sc-553
    // modification start - 01.24.2024 - maco-magsael-sc-568
    manageBoatID,
    manageBoatName,
    // modification end - 01.24.2024 - maco-magsael-sc-568
  });

  try {
    emailjs.init(USER_ID);
    const res = await axios.post('/api/users', body, config);

    const profilePhoto = res.data.profilePhoto;
    const userID = res.data._id;
    const boatName = res.data._boatName;
    const boatID = res.data.boatID;
    const newEdit = true;

    const body2 = JSON.stringify({
      firstName,
      lastName,
      department,
      jobtitle,
      mailing,
      role,
      username,
      profilePhoto,
      userID,
      boatName,
      boatID,
      newEdit,
      email,
      // modification start - 07.22.2023 - management-module
      managementID,
      // modification end - 07.22.2023 - management-module
    });

    const res2 = await axios.post('/api/profile', body2, config);

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: res2.data,
    });

    setNotification('success', 'New user created');

    const fullName =
      firstName.charAt(0).toUpperCase() +
      firstName.slice(1) +
      ' ' +
      lastName.charAt(0).toUpperCase() +
      lastName.slice(1);
    const login = window.location.origin + `/auth/login`;

    const params = {
      boatname: formData.boatName,
      user: fullName,
      password: password,
      userEmail: email,
      login: login,
    };

    if (res.data && res2.data) {
      await emailjs.send(SERVICE_ID, TEMPLATE_ID, params);
    } else {
      setNotification('error', 'Sending new user email failed');
    }
    history.push(`${APP_PREFIX_PATH}/profiledetails/${res2.data._id}`);
  } catch (err) {
    console.info('err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        setNotification('error', error.msg);
      });
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Creating new user failed');
  }
};

// modification start - 08.30.2022 - ympshipyard
// Create shipyard user
export const createShipyardUser = (formData, history) => async (dispatch) => {
  dispatch({
    type: SHOW_PROFILE_LOADING,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const firstName = formData.firstName;
  const lastName = formData.lastName;
  const username = formData.username;
  const email = formData.email;
  const role = formData.role;

  const body = JSON.stringify({
    username,
    role,
  });

  try {
    const res = await axios.post('/api/users/shipyard', body, config);

    const profilePhoto = res.data.profilePhoto;
    const userID = res.data._id;
    const boatName = res.data._boatName;
    const boatID = res.data.boatID;
    const newEdit = true;

    const body2 = JSON.stringify({
      firstName,
      lastName,
      role,
      email,
      username,
      profilePhoto,
      userID,
      boatName,
      boatID,
      newEdit,
    });

    const res2 = await axios.post('/api/profile/shipyard', body2, config);

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: res2.data,
    });

    setNotification('success', 'New user created');
    history.push(`${APP_PREFIX_PATH}/userdetails/${res2.data._id}`);
  } catch (err) {
    console.info('err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        setNotification('error', error.msg);
      });
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Creating new user failed');
  }
};
// modification end - 08.30.2022 - ympshipyard

// Edit user system access
export const editSystemAccess = (access, field, userID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    field,
    access,
  });

  try {
    const res = await axios.put(
      `/api/users/system_access/${userID}`,
      body,
      config
    );

    dispatch({
      type: GET_USER_DETAILS,
      payload: res.data,
    });

    dispatch(loadUser());

    setNotification('success', 'System access have changed');
  } catch (err) {
    console.info('err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        setNotification('error', error.msg);
      });
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Updating system access failed');
  }
};

// modification start - 09.05.2024 - olain-tarcina-sc-681
// Switch boats
export const switchBoat = (boatID, boatName, liteBoat) => async (dispatch) => {
  dispatch({
    type: USER_LOADING,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    boatID,
    boatName,
    liteBoat,
  });

  try {
    const res = await axios.put('/api/users/switch-ympliteboat', body, config);

    dispatch({
      type: GET_USER_DETAILS,
      payload: res.data,
    });

    dispatch(loadUser());

    setNotification('success', 'Switched to ' + boatName);
  } catch (err) {
    console.info('err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        setNotification('error', error.msg);
      });
    }

    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Switching boats failed');
  }
};
// modification end - 09.05.2024 - olain-tarcina-sc-681

// Switch department
export const switchDepartment =
  (userID, department, jobtitle) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      department,
      jobtitle,
    });

    try {
      const res = await axios.put(
        `/api/users/switch_department/${userID}`,
        body,
        config
      );

      dispatch({
        type: GET_USER_DETAILS,
        payload: res.data,
      });

      dispatch(loadUser());
    } catch (err) {
      console.info('err', err);
      dispatch({
        type: USER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'updating user failed');
    }
  };

// Change password
export const changePassword = (formData, history) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const { newPassword } = formData;

  const body = JSON.stringify({
    newPassword,
  });

  try {
    await axios.put(`/api/users/change_password`, body, config);
    dispatch(loadUser());

    setNotification('success', 'Successfully changed password');
    history.push(`${APP_PREFIX_PATH}/home`);
  } catch (err) {
    console.info('err', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Failed to change password');
  }
};

// Delete User
export const deleteUser = (id, history) => async (dispatch) => {
  // Modification start - 17.10.2022 - olain-tarcina-sc-226
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const softDelete = true;

  const body = JSON.stringify({
    softDelete,
  });
  // Modification end - 17.10.2022 - olain-tarcina-sc-226
  try {
    // Modification start - 17.10.2022 - olain-tarcina-sc-226
    axios.put(`/api/users/softdelete/${id}`, body, config);
    // Modification end - 17.10.2022 - olain-tarcina-sc-226

    // modification start - 06.22.2023 - maco-magsael-sc-440
    dispatch(loadUser());
    // modification end - 06.22.2023 - maco-magsael-sc-440

    setNotification('success', 'User successfully deleted');
    history.push(`${APP_PREFIX_PATH}/employees`);
  } catch (err) {
    console.info('err', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Error deleting user');
  }
};

// modification start - 05.24.2024 - maco-magsael-sc-643
// Delete User
export const deleteShipyardUser = (id) => async (dispatch) => {
  dispatch({
    type: SHOW_USER_LOADING,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const softDelete = true;

  const body = JSON.stringify({
    softDelete,
  });
  try {
    axios.put(`/api/users/softdelete/${id}`, body, config);
    dispatch({
      type: DELETE_USER,
      payload: id,
    });

    setNotification('success', 'User successfully deleted');
  } catch (err) {
    console.info('err', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Error deleting user');
  }
};
// modification end - 05.24.2024 - maco-magsael-sc-643

// Modification start - 13.09.2022 - ympolain-178
export const restoreUser = (id, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // Modification start - 17.10.2022 - olain-tarcina-sc-226
    const softDelete = true;

    const body = JSON.stringify({
      softDelete,
    });

    axios.put(`/api/users/softdelete/${id}`, body, config);
    // Modification end - 17.10.2022 - olain-tarcina-sc-226

    setNotification('success', 'User successfully restored');
    history.push(`${APP_PREFIX_PATH}/users`);
  } catch (err) {
    console.info('err', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Error deleting user');
  }
};
// Modification end - 13.09.2022 - ympolain-178

// Modification 04.08.2022 - start - ymponline-140
// Update Role
export const updateRole = (role, userID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    role,
    userID,
  });

  try {
    await axios.put('/api/users/role', body, config);

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
    });
  } catch (err) {
    console.info('err', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Error updating user role');
  }
};
// Modification 04.08.2022 - end - ymponline-140

// Modification start - 30.11.2023 - olain-tarcina-sc-550
// Update email
export const updateEmail = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const email = formData.email;
  const id = formData.userID;

  const body = JSON.stringify({
    email,
  });

  try {
    const res = await axios.put(`/api/users/email/${id}`, body, config);

    dispatch({
      type: GET_USER_DETAILS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    console.info('err', err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Error updating email');
  }
};
// Modification end - 30.11.2023 - olain-tarcina-sc-550

// Modification start - 04.24.2024 - new-shipyard
// Invite user in Shipyard Job
export const inviteShipyardUser =
  // modification start - 05.24.2024 - maco-magsael-sc-643
  (formData, emailExists, inviteUser, jobID, userType) => async (dispatch) => {
    // modification end - 05.24.2024 - maco-magsael-sc-643
    dispatch({
      type: SHOW_USER_SHIPYARD_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { inviteEmail, password } = formData;
    const email = inviteEmail;
    const { inviteUserID } = inviteUser;
    let userID = inviteUserID;

    // modification start - 05.24.2024 - maco-magsael-sc-643
    const shipyardJobID = jobID;
    // modification end - 05.24.2024 - maco-magsael-sc-643
    const role = 'user';
    const username = email;
    let getColor = '#' + Math.random().toString(16).slice(2, 8);
    const borderColor = getColor;
    const backgroundColor = getColor;
    // modification start - 07.04.2024 - maco-magsael-sc-661
    const mailing = 'Subscribed';
    // modification end - 07.04.2024 - maco-magsael-sc-661

    const body3 = JSON.stringify({
      username,
      role,
      email,
      password,
      borderColor,
      backgroundColor,
      userType,
      // modification start - 05.24.2024 - maco-magsael-sc-643
      jobID,
      // modification end - 05.24.2024 - maco-magsael-sc-643
      // modification start - 07.04.2024 - maco-magsael-sc-661
      mailing,
      // modification end - 07.04.2024 - maco-magsael-sc-661
    });

    try {
      if (!emailExists) {
        //create user with email
        const SERVICE_ID = 'service_toqfoo9';
        const TEMPLATE_ID = 'template_5ps2c2e';
        const USER_ID = 'user_WtCjGRDorUo3I1OmblHuB';
        emailjs.init(USER_ID);
        const res3 = await axios.post(
          '/api/users/shipyard/invites',
          body3,
          config
        );

        const profilePhoto = res3.data.profilePhoto;
        userID = res3.data._id;
        const boatName = res3.data.boatName;
        const boatID = res3.data.boatID;
        const newEdit = true;
        // modification start - 05.24.2024 - maco-magsael-sc-643
        const managementID = res3.data.managementID;
        // modification end - 05.24.2024 - maco-magsael-sc-643

        const firstName = '';
        const lastName = '';
        const body4 = JSON.stringify({
          firstName,
          lastName,
          role,
          email,
          username,
          profilePhoto,
          userID,
          boatName,
          boatID,
          userType,
          newEdit,
          // modification start - 05.24.2024 - maco-magsael-sc-643
          managementID,
          // modification end - 05.24.2024 - maco-magsael-sc-643
          // modification start - 07.04.2024 - maco-magsael-sc-661
          mailing,
          // modification end - 07.04.2024 - maco-magsael-sc-661
        });

        const res4 = await axios.post(
          '/api/profile/shipyard/invite',
          body4,
          config
        );

        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: res4.data,
        });

        setNotification('success', 'New user created');
        const login = window.location.origin + `/auth/login`;

        const params = {
          boatname: boatName,
          user: 'new user',
          password: password,
          userEmail: email,
          login: login,
        };

        if (res3.data && res4.data) {
          await emailjs.send(SERVICE_ID, TEMPLATE_ID, params);

          const res5 = await axios.get(`/api/users/all_users`);

          dispatch({
            type: GET_USERS,
            payload: res5.data,
          });
        } else {
          setNotification('error', 'Sending new user email failed');
        }
        //add job in user and shipyardUser=true shipyard=true

        //add user in job
      } else {
        const body2 = JSON.stringify({
          shipyardJobID,
          userType,
        });
        await axios.put(`/api/users/add_shipyard/${userID}`, body2, config);
      }

      const body = JSON.stringify({
        userID,
      });
      // modification start - 05.24.2024 - maco-magsael-sc-643
      if (userType === 'contractUser') {
        const res = await axios.put(
          `/api/worklist/add_contractuser/${jobID}`,
          body,
          config
        );

        dispatch({
          type: GET_JOBDETAILS,
          payload: res.data,
        });
      }
      // modification end - 05.24.2024 - maco-magsael-sc-643

      setNotification('success', 'User invited');
    } catch (err) {
      console.info('err', err);
      dispatch({
        type: USER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Error inviting user');
    }
  };
// Modification end - 04.24.2024 - new-shipyard
