import {
  GET_LITE_EQUIPMENT,
  LITE_EQUIPMENT_ERROR,
  SHOW_LITE_EQUIPMENT_LOADING,
  GET_LITE_EQUIPMENT_DETAILS,
  CREATE_LITE_EQUIPMENT_SUCCESS,
  SHOW_EDIT_LITE_EQUIPMENT_LOADING,
  EDIT_LITE_EQUIPMENT_SUCCESS,
  EDIT_LITE_EQUIPMENT_LOGBOOK_SUCCESS,
  DELETE_LITE_EQUIPMENT,
  EDIT_CURRENT_HOURS_SUCCESS,
} from '../actions/types';

/** Modifications
 * olain-tarcina-sc-681 - ymplite registration
 */

const initialState = {
  litegrouplist: [],
  litegroupID: null,
  litegroupdetails: null,
  liteloading: false,
  liteeditloading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITE_EQUIPMENT_LOADING:
      return {
        ...state,
        liteloading: true,
      };
    case SHOW_EDIT_LITE_EQUIPMENT_LOADING:
      return {
        ...state,
        liteeditloading: true,
      };
    case GET_LITE_EQUIPMENT:
      return {
        ...state,
        litegrouplist: payload,
        liteloading: false,
      };
    case EDIT_LITE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        litegrouplist: state.litegrouplist.map((litegroup) =>
          litegroup._id === payload._id
            ? {
                ...litegroup,
                name: payload.name,
                location: payload.location,
                modelNumber: payload.modelNumber,
                serialNumber: payload.serialNumber,
                manufacturer: payload.manufacturer,
                remarks: payload.remarks,
                critical: payload.critical,
                hours: payload.hours,
                isArchived: payload.isArchived,
                department: payload.department,
              }
            : litegroup
        ),
        liteeditloading: false,
      };
    case EDIT_LITE_EQUIPMENT_LOGBOOK_SUCCESS:
      return {
        ...state,
        litegrouplist: state.litegrouplist.map((litegroup) =>
          litegroup._id === payload._id
            ? {
                ...litegroup,
                atSea: payload.atSea,
                atPort: payload.atPort,
              }
            : litegroup
        ),
        liteeditloading: false,
      };
    case EDIT_CURRENT_HOURS_SUCCESS:
      return {
        ...state,
        liteloading: false,
      };
    case GET_LITE_EQUIPMENT_DETAILS:
      return {
        ...state,
        litegroupdetails: payload,
      };
    case CREATE_LITE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        litegrouplist: [payload, ...state.litegrouplist],
        liteloading: false,
      };
    case LITE_EQUIPMENT_ERROR:
      return {
        ...state,
        error: payload,
        liteloading: false,
      };
    case DELETE_LITE_EQUIPMENT:
      return {
        ...state,
        litegrouplist: state.litegrouplist.filter(
          (litegroup) => litegroup._id !== payload
        ),
        liteloading: false,
      };
    default:
      return state;
  }
}
