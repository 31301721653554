import React, { useState, Fragment } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { connect } from 'react-redux';
import { DIR_RTL } from 'constants/ThemeConstant';

export const NavPanel = ({ theme: { direction, locale } }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fragment>
      <Menu mode="horizontal">
        <Menu.Item key="panel" onClick={() => setVisible(true)}>
          <SettingOutlined className="nav-icon mr-0" />
        </Menu.Item>
      </Menu>
      <Drawer
        title="Theme Config"
        placement={direction === DIR_RTL ? 'left' : 'right'}
        width={350}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <ThemeConfigurator />
      </Drawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
});

export default connect(mapStateToProps, null)(NavPanel);
